<template>
    <div class="dot" ref="dot" :style="{ 'background-color': color }">
        <div class="line" ref="line"></div>
    </div>
</template>

<style lang="scss" scoped>
    .dot{
        position:relative;
        display:inline-block;

        width:0.15em;
        height:0.15em;

        background-color:black;
        border-radius:1000px;
        margin-left:0.1em;

        .line{
            position:absolute;
            left:0;
            top:50%;
            width:10000px;
            height:1px;
            background-color:inherit;

            opacity:0.5;
            transform-origin:left center;
            transform:scaleX( 1 );
        }
    }
</style>

<script>
    import { gsap } from '@/vendor';

    export default {
        props: {
            color: {
                type: String,
                default: 'black'
            }
        },

        mounted()
        {
        },

        methods: {
            anim()
            {
                let { dot, line } = this.$refs;

                let tl = gsap.timeline();
                tl.fromTo( dot, { scale: 5, opacity: 0 }, { scale: 1, opacity: 1, duration: 0.3 });
                tl.from( line, { scaleX: 0, delay: 0.3, duration: 5 });

                return tl;
            }
        }
    };
</script>
