<template>
    <section class="mad-society">
        <h2>The society of<br/>Mad. Avenue<v-dot ref="dot"></v-dot></h2>

        <div class="row">
            <div class="col-12 m-col-6">
                <div class="img-wrapper hide-for-small">
                    <div class="fixed-img"></div>
                    <div class="fixed-img"></div>
                </div>
            </div>
            <div class="col-12 m-col-6">
                <!-- <p>Na een samenwerking van meerdere jaren besloten Gert van der Kaay & Daniel Kok in 2020 een bureau op te richten waar goed werk, slagvaardigheid en betrouwbare samenwerkingen de boventoon voeren.</p> -->

                <p>Collaborate and create solutions with the minds of Mad. Avenue. Each with their own set of skills and expertise. Problem solvers. Challengers. Connectors. Entrepreneurs. Online & offline. A rare society with one powerful similarity; always the right talent for building your brand.</p>

                <div class="fixed-img show-for-small"></div>
                <!-- <img src="/static/img/mad-society2.jpg" class="show-for-small" /> -->
                <!-- <h4>Gert van der Kaay - Creative Director</h4>
                <p>Ondernemende art director en bedenker van middelenvrije, creatieve concepten die in dienst staan van het succes van een onderneming. Maakt niet alleen zelf prijswinnend werk, maar weet ook anderen te inspireren tot het ontwikkelen van opmerkelijke communicatie die zich onderscheidt door onderscheidend te zijn.</p> -->
                <div class="fixed-img show-for-small"></div>
                <!-- <img src="/static/img/mad-society1.jpg" class="show-for-small" /> -->
                <!-- <h4>Daniel Kok- Business Director</h4>
                <p>Werkte niet alleen aan de bureaukant, maar ook aan klantzijde. Is mede daardoor een bruggenbouwer geworden als het gaat om het verbinden van data, klantinzichten en creatie. Staat met hart en ziel aan de wieg van creatieve campagnes die ook uitermate effectief blijken te zijn. Heeft bovendien ervaren dat naast expertise, kunde en talent, vertrouwen de basis is van iedere samenwerking.</p> -->
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
    .img-wrapper{
        min-height:470px;
    }

    .fixed-img{
        position:absolute;
        width:70%;
        height: 0;
        bottom:50%;
        padding-bottom:70%;
        background-color:rgba( 0,0,255,0.2 );

        background:transparent url( '/static/img/mad-society1.jpg' ) no-repeat center center;
        background-size:100% auto;

        &:last-of-type{
            top:auto;
            bottom:0;
            right:0;

            background-image:url( '/static/img/mad-society2.jpg' );
        }

        &.show-for-small{
            position:static;
            max-width: 49%;
            &:last-of-type{
                margin: 0 -2rem;
            }
        }
    }
</style>

<script>
    import { gsap } from '@/vendor';
    import Dot from '@/app/components/Dot';

    export default {
        components: {
            'v-dot': Dot
        },

        mounted()
        {
            window.addEventListener( 'scroll', this.onScroll.bind( this ) );
        },

        methods: {
            onScroll()
            {
                this.animPos = Math.max( -600, Math.min( 600, this.$el.offsetTop - window.scrollY ) );
                if( window.innerWidth < 720 )
                {
                    gsap.to( '.fixed-img:first-of-type', 0.1, { backgroundPositionY: this.animPos / 4 });
                    gsap.to( '.fixed-img:last-of-type', 0.1, { backgroundPositionY: ( this.animPos / 4 ) + 100 });
                }
                else
                {
                    gsap.to( '.fixed-img:first-of-type', 0.1, { backgroundPositionY: this.animPos / 3 });
                    gsap.to( '.fixed-img:last-of-type', 0.1, { backgroundPositionY: this.animPos * 2 });
                }
            },

            anim()
            {
                let tl = gsap.timeline();

                tl.from( '.mad-society h2', { y: 20, opacity: 0 }, 0 );
                tl.add( this.$refs.dot.anim(), 1 );

                tl.from( '.mad-society p', { x: 40, opacity: 0, stagger: 0.1 }, 0.3 );
                // tl.from( '.mad-society h4', { x: 40, opacity: 0, stagger: 0.1 }, 0.3 );
                tl.from( '.mad-society .fixed-img', { x: -20, opacity: 0, stagger: 0.3 }, 0.5 );

                return tl;
            }
        }
    };
</script>
