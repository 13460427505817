/* eslint-disable */
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin( ScrollTrigger );

export {
    gsap,
    ScrollTrigger
};
