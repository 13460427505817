<template>
    <div id="app">
        <ynk-layout>
            <v-madavenue-logo :small="logoIsSmall || menuOpen"></v-madavenue-logo>
            <v-hamburger :open="menuOpen" @click.native="toggleMenu"></v-hamburger>

            <nav slot="nav">
                <div class="logo-slot">
                    <!-- <img src="/static/img/mad-avenue-logo.png" /> -->
                </div>

                <div :class="{ 'curtain-menu': true, 'open': menuOpen }" ref="menu">

                    <ul>
                        <li><a @click="toggleMenu" href="#mad-avenue">Mad. Avenue</a></li>
                        <li><a @click="toggleMenu" href="#what-we-do-best">Services</a></li>
                        <li><a @click="toggleMenu" href="#mad-works">Mad. Works</a></li>
                        <li><a @click="toggleMenu" href="#mad-society">The society</a></li>
                    </ul>

                    <div class="contact">
                        <h4>Contact</h4>
                        <p>Daniel Kok</p>
                        <br/>
                        <p>Jacob Burggraafstraat 182</p>
                        <p>1018 WA | Amsterdam</p>
                        <br/>
                        <a target="_blank" href="tel:+31202232722">+ 31 20 223 27 22 <v-dot color="white"></v-dot></a>
                        <br />
                        <br />
                        <a target="_blank" href="mailto:info@madavenue.nl">info@madavenue.nl</a>
                    </div>

                    <div class="social">
                        <a href="https://www.instagram.com/mad.avenue/" target="_blank"><img src="/static/img/logos/instagram.svg" /></a>
                        <a href="https://www.linkedin.com/company/madavenue/" target="_blank"><img src="/static/img/logos/linkedin.svg" /></a>
                        <a href="https://www.facebook.com/madavenueagency/" target="_blank"><img src="/static/img/logos/facebook.svg" /></a>
                        <a href="https://vimeo.com/madavenue" target="_blank"><img src="/static/img/logos/vimeo.svg" /></a>
                    </div>

                </div>

            </nav>

            <v-title ref="title" id="#"></v-title>

            <v-mad-avenue ref="madAvenue" id="mad-avenue"></v-mad-avenue>

            <v-showreel ref="showreel" id="showreel"></v-showreel>

            <section><span style="color: white; user-select: none;">designer void</span><!-- nothing here --></section>

            <v-what-we-do-best ref="whatWeDoBest" id="what-we-do-best"></v-what-we-do-best>

            <v-brand-portfolio ref="brandPortfolio" id="brands"></v-brand-portfolio>

            <v-madworks ref="madWorks" id="mad-works"></v-madworks>

            <v-mad-society ref="madSociety" id="mad-society"></v-mad-society>

            <footer>
                <img class="mad-logo hide-for-small" src="/static/img/mad-avenue-logo-small.png" />

                <div class="row">
                    <div class="hide-for-small m-col-4"></div>
                    <div class="col-6 m-col-4 mini-nav">
                        <a href="#mad-avenue">Mad. Avenue</a>
                        <a href="#what-we-do-best">Services</a>
                        <a href="#mad-works">Mad. Works</a>
                        <a href="#mad-society">The society</a>
                    </div>
                    <div class="col-6 m-col-4 contact">
                        <h4>Contact</h4>
                        <p>Daniel Kok</p>
                        <br/>
                        <p>Jacob Burggraafstraat 182</p>
                        <p>1018 WA | Amsterdam</p>
                        <br/>
                        <a target="_blank" href="tel:+31202232722">+ 31 20 223 27 22 <v-dot color="white"></v-dot></a>
                        <br />
                        <br />
                        <a target="_blank" href="mailto:info@madavenue.nl">info@madavenue.nl</a>
                        <br />
                        <br />
                        <br />
                        <br />
                        <div class="social">
                            <a href="https://www.instagram.com/mad.avenue/" target="_blank"><img src="/static/img/logos/instagram.svg" /></a>
                            <a href="https://www.linkedin.com/company/madavenue/" target="_blank"><img src="/static/img/logos/linkedin.svg" /></a>
                            <a href="https://www.facebook.com/madavenueagency/" target="_blank"><img src="/static/img/logos/facebook.svg" /></a>
                            <a href="https://vimeo.com/madavenue" target="_blank"><img src="/static/img/logos/vimeo.svg" /></a>
                        </div>
                    </div>
                </div>

                <p class="legal">&copy; Mad. Avenue 2020 all rights reserved</p>
            </footer>
        </ynk-layout>
    </div>
</template>

<style lang="scss">
    @import './static/scss/app.scss';
</style>

<script>
    import { ScrollTrigger } from '@/vendor';
    import Main from '@/app/layouts/Main';

    import MadAvenueLogo from '@/app/components/MadAvenueLogo';
    import Hamburger from '@/app/components/Hamburger';

    import Title from '@/app/sections/Title';
    import MadAvenue from '@/app/sections/MadAvenue';
    import ShowreelVideo from '@/app/sections/ShowreelVideo';
    import WhatWeDoBest from '@/app/sections/WhatWeDoBest';
    import BrandPortfolio from '@/app/sections/BrandPortfolio';
    import MadWorks from '@/app/sections/MadWorks';
    import MadSociety from '@/app/sections/MadSociety';

    import Dot from '@/app/components/Dot';

    export default {
        name: 'App',

        components: {
            'ynk-layout': Main,

            'v-madavenue-logo': MadAvenueLogo,
            'v-hamburger': Hamburger,

            'v-title': Title,
            'v-mad-avenue': MadAvenue,
            'v-showreel': ShowreelVideo,
            'v-what-we-do-best': WhatWeDoBest,
            'v-brand-portfolio': BrandPortfolio,
            'v-madworks': MadWorks,
            'v-mad-society': MadSociety,

            'v-dot': Dot
        },

        data()
        {
            return {
                scrollOffset: null,
                menuOpen: false
            };
        },

        computed: {
            logoIsSmall()
            {
                if( this.scrollOffset > 0 )
                {
                    return true;
                }

                return false;
            }
        },

        mounted()
        {
            window.addEventListener( 'resize', this.onResize );
            window.addEventListener( 'scroll', this.onScroll );

            this.onResize();

            this.handleAnim();

            // infinite logo anim, remove when done
            // let tl = gsap.timeline({ repeat: -1 });
            // tl.add( () =>
            // {
            //     this.logoIsSmall = !this.logoIsSmall;
            // }, 1 );
        },

        methods: {
            handleAnim()
            {
                let pages = [ this.$refs.title, this.$refs.madAvenue, this.$refs.whatWeDoBest, this.$refs.brandPortfolio, this.$refs.madWorks, this.$refs.madSociety ];

                for( let page of pages )
                {
                    if( !page.anim )
                    {
                        continue;
                    }

                    let anim = page.anim();

                    ScrollTrigger.create({
                        // markers: true,
                        start: 'top 50%',
                        end: 'bottom 100%',

                        animation: anim,
                        trigger: page.$el
                    });
                }
            },

            toggleMenu()
            {
                this.menuOpen = !this.menuOpen;
            },

            onScroll()
            {
                this.scrollOffset = window.scrollY;
            },

            onResize()
            {
                document.documentElement.style.setProperty( '--vh100', window.innerHeight + 'px' ); // fix for 100vh on iphones
            }
        }
    };
</script>
