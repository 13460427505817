<template>
    <section class="mad-avenue">
        <div class="row">
            <h2>Mad. Avenue</h2>
        </div>
        <div class="row">
            <div class="col-12 m-col-6">
                <p>Meet Mad. Avenue an agile and open-minded agency. With a small core and a great circle of highly talented satellites for each and every segment of the complex media landscape. <v-dot ref="dot"></v-dot></p>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
</style>

<script>
    import { gsap } from '@/vendor';
    import Dot from '@/app/components/Dot';

    export default {
        components: {
            'v-dot': Dot
        },

        methods: {
            anim()
            {
                let tl = gsap.timeline();

                tl.from( '.mad-avenue h2', { y: 20, opacity: 0 });
                tl.from( '.mad-avenue .col-6', { y: 20, opacity: 0, stagger: 0.15 });
                tl.add( this.$refs.dot.anim(), 1 );

                return tl;
            }
        }
    };
</script>
