<template>
    <section class="mad-works">
        <h2 class="show-for-small">Mad. Works</h2>
        <div class="row">
            <div class="col-12 m-col-6 l-col-4">
                <mad-works-anim ref="anim"></mad-works-anim>
                <div class="steps">
                    <div class="empatize">
                        <h3>Empatize</h3>
                        <p>The underrated art of understanding</p>
                    </div>

                    <div class="define">
                        <h3>Define</h3>
                        <p>Creating starts with defining</p>
                    </div>
                    <div class="ideate">
                        <h3>Ideate</h3>
                        <p>The trick is to avoid the same tricks</p>
                    </div>
                    <div class="produce">
                        <h3>Produce</h3>
                        <p>Saving rich ideas from poor execution</p>
                    </div>
                    <div class="learn">
                        <h3>Learn</h3>
                        <p>Turning quicksand into concrete</p>
                    </div>
                </div>
            </div>
            <div class="col-12 m-col-6 l-col-8">
                <h2 class="hide-for-small">Mad. Works</h2>
                <!-- <p class="description">Maak kennis met onze versie van Design Thinking: Mad. Works. Mad. Works is een probleemoplossende methode die tot buitengewone creatieve resultaten leidt. Het is gebaseerd op het gebruik van data die wordt gecombineerd met specifieke klantbehoeften. En het werkt elke keer anders. Terwijl het doel altijd hetzelfde is: het realiseren van innovatieve oplossingen en concepten <v-dot ref="dot" color="white"></v-dot></p> -->
                <p class="description">An ultra-fast problem-solving method that leads to extraordinary creative results in an intuitive way. <v-dot ref="dot" color="white"></v-dot></p>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { gsap } from '@/vendor';
    import Dot from '@/app/components/Dot';
    import MadWorksAnim from '@/app/components/MadWorksAnim';

    export default {
        components: {
            'v-dot': Dot,
            'mad-works-anim': MadWorksAnim
        },

        methods: {
            anim()
            {
                let tl = gsap.timeline();

                tl.from( ['.mad-works h2', '.mad-works .description', '.mad-works img'], { y: 20, opacity: 0, stagger: 0.2 });
                tl.add( this.$refs.dot.anim(), 1 );
                tl.add( () => { this.$refs.anim.restart(); }, 2 );

                return tl;
            }
        }
    };
</script>
