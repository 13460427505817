<template>
    <div :class="{ 'hamburger': true, 'open': open }">
        <div class="inner">
            <div class="line"></div>
            <div class="line"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { gsap } from '@/vendor';

    export default {
        props: {
            open: {
                type: Boolean,
                default: false
            }
        },

        watch: {
            open: function( pVal )
            {
                if( pVal === true )
                {
                    this.toCross();
                }
                else
                {
                    this.toLines();
                }
            }
        },

        mounted()
        {
            this.topLine = this.$el.querySelector( '.line:first-of-type' );
            this.bottomLine = this.$el.querySelector( '.line:last-of-type' );
        },

        methods: {
            toCross()
            {
                let tl = gsap.timeline();

                gsap.set( [ this.topLine, this.bottomLine ], { transformOrigin: 'center center' });

                tl.to( this.topLine, { rotation: 45, y: '100%', duration: 0.3 }, 0 );
                tl.to( this.bottomLine, { rotation: -45, y: '-100%', duration: 0.3 }, 0 );
            },

            toLines()
            {
                let tl = gsap.timeline();

                gsap.set( [ this.topLine, this.bottomLine ], { transformOrigin: 'center center' });

                tl.fromTo( this.topLine, { x: 0, y: '100%', rotation: 45 }, { x: 0, y: 0, rotation: 0, duration: 0.3 }, 0 );
                tl.fromTo( this.bottomLine, { x: 0, y: '-100%', rotation: -45 }, { x: 0, y: 0, rotation: 0, duration: 0.3 }, 0 );
            }
        }
    };
</script>
