<template>
    <section class="brand-portfolio">
        <p>Brands we've worked for:</p>

        <div class="brand-wrapper row">
            <div class="col-6 m-col-3">
                <v-slick ref="slick1">
                    <img src="/static/img/logos/bacardi.svg" />
                    <img src="/static/img/logos/dirk.svg" />
                    <img src="/static/img/logos/KHN.svg" />
                    <img src="/static/img/logos/thefork.svg" />
                </v-slick>
            </div>
            <div class="col-6 m-col-3">
                <v-slick ref="slick2">
                    <img src="/static/img/logos/basicfit.svg" />
                    <img src="/static/img/logos/doritos.svg" />
                    <img src="/static/img/logos/mercedes.svg" />
                    <img src="/static/img/logos/verkade.svg" />
                </v-slick>
            </div>
            <div class="col-6 m-col-3">
                <v-slick ref="slick3">
                    <img src="/static/img/logos/orangina.svg" />
                    <img src="/static/img/logos/binck.svg" />
                    <img src="/static/img/logos/eon.svg" />
                    <img src="/static/img/logos/npo.svg" />
                </v-slick>
            </div>
            <div class="col-6 m-col-3">
                <v-slick ref="slick4">
                    <img src="/static/img/logos/rakuten.svg" />
                    <img src="/static/img/logos/swapfiets.svg" />
                    <img src="/static/img/logos/hema.svg" />
                    <img src="/static/img/logos/schweppes.svg" />
                </v-slick>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>

</style>

<script>
    import { gsap } from '@/vendor';
    import Slick from '@/app/components/Slick';

    export default {
        components: {
            'v-slick': Slick
        },

        methods: {
            anim()
            {
                let tl = gsap.timeline();

                tl.from( '.brand-portfolio p', { y: 10, opacity: 0 });
                tl.from( '.brand-portfolio .col-6', { y: 10, opacity: 0, stagger: 0.1 });

                tl.add( this.$refs.slick1.startAutoplay, 1 );
                tl.add( this.$refs.slick2.startAutoplay, 1.1 );
                tl.add( this.$refs.slick3.startAutoplay, 1.2 );
                tl.add( this.$refs.slick4.startAutoplay, 1.3 );

                return tl;
            }
        }
    };
</script>
