<template>
    <div class="slider-wrapper">
        <v-slick-carousel ref="carousel" v-bind="settings" >
            <slot></slot>
        </v-slick-carousel>

        <div class="dots">

        </div>
    </div>
</template>

<style lang="scss" scoped>

</style>

<script>
    import VueSlickCarousel from 'vue-slick-carousel';

    import 'vue-slick-carousel/dist/vue-slick-carousel.css';
    import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

    export default {
        components: {
            'v-slick-carousel': VueSlickCarousel
        },

        data()
        {
            return {
                settings: {
                    dots: false,
                    arrows: false,
                    vertical: true,
                    verticalSwiping: true,
                    autoplay: false,
                    autoplaySpeed: 750,
                    pauseOnHover: false,
                    draggable: false
                }
            };
        },

        mounted()
        {
        },

        methods: {
            startAutoplay()
            {
                this.$refs.carousel.play();
            }
        }
    };
</script>
