<template>
    <section class="showreel" @click="video = true" style="cursor: pointer;">
        <div class="row">
            <div class="col-12 m-col-12">
                <div class="pre-video" v-if="!video" >
                    <h2>Curious what we make?</h2>
                    <p>
                        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0h48v48H0z" fill="none"/>
                            <path d="M20 33l12-9-12-9v18zm4-29C12.95 4 4 12.95 4 24s8.95 20 20 20 20-8.95 20-20S35.05 4 24 4zm0 36c-8.82 0-16-7.18-16-16S15.18 8 24 8s16 7.18 16 16-7.18 16-16 16z" fill="white"/>
                        </svg>
                        Check out the highlights behind the Mad. Avenue team<v-dot ref="dot" color="white"></v-dot></p>
                </div>
            </div>
        </div>
        <div class="video" v-if="video">
            <video src="/static/video/showreel.mp4" playsinline controls autoplay @ended="video = false"></video>
        </div>
    </section>
</template>

<style lang="scss" scoped>
    .showreel {
        background: transparent url(/static/img/showreel.jpg) no-repeat center center;
        background-size: cover;
        color: white;
        // padding: 2rem;
        p {
            font-size: 1rem;
        }
        .pre-video {
            padding: 3em;
            svg {
                width: 1em;
            }
        }
        .video {
            padding-bottom: 56.25%;
        }
        video {
            position: absolute;
            top: 0;
            left: 50%;
            width: 100%;
            max-width: 100%;
            transform: translateX(-50%);
            background: black;
        }
    }
</style>

<script>
    import { gsap } from '@/vendor';
    import Dot from '@/app/components/Dot';

    export default {
        components: {
            'v-dot': Dot
        },
        data()
        {
            return {
                video: false
            };
        },
        mounted()
        {
            document.addEventListener( 'scroll', this.onScroll.bind( this ) );
        },
        methods: {
            onScroll( e )
            {
                this.$refs.bg.style.backgroundPosition = 'center ' + ( window.scrollY / 3 ) + 'px';
            },
            anim()
            {
                let tl = gsap.timeline();
                // tl.from( this.$el.querySelector( 'h1' ), { x: 100, opacity: 0, duration: 0.5 }, 1 );
                // tl.from( this.$el.querySelector( 'h1 span' ), { y: 100, opacity: 0, ease: 'back', duration: 0.5 }, 1.5 );

                // tl.to( this.$el.querySelector( 'h1' ), { y: -10, yoyo: true, repeat: 1, duration: 0.15 }, 1.75 );
                // tl.to( this.$el.querySelector( 'h1 span' ), { y: 0, yoyo: true, repeat: 1, duration: 0.15 }, 1.75 );

                tl.from( '.title h1', { y: 100, duration: 0.5, stagger: 0.2 }, 1 );

                return tl;
            }
        }
    };
</script>
