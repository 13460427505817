<template>
    <section class="what-we-do-best">
        <div class="visual" ref="visual"></div>
        <div class="row">
            <div class="col-12 m-col-6">
                <h2>What we do best.</h2>
                <v-dot ref="dot" color="white"></v-dot>
            </div>
            <div class="col-12 m-col-6">
                <div class="row">
                    <div class="col-5">
                        <ul>
                            <li>Research</li>
                            <li>Strategy</li>
                            <li>Creative Concepts</li>
                            <li>Social Campaigns</li>
                            <li>Design</li>
                            <li>Data Science</li>
                            <li>Media</li>
                        </ul>
                    </div>
                    <div class="col-7">
                        <ul>
                            <li>Content Production</li>
                            <li>Photography</li>
                            <li>Film & Video Production</li>
                            <li>Online Production</li>
                            <li>Desktop Publishing</li>
                            <li>Image Retouch</li>
                            <li>Print Production & Handling</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style lang="scss" scoped>
</style>

<script>
    import { gsap } from '@/vendor';
    import Dot from '@/app/components/Dot';

    export default {
        components: {
            'v-dot': Dot
        },

        mounted()
        {
            document.addEventListener( 'scroll', this.onScroll.bind( this ) );
        },

        methods: {
            onScroll( e )
            {
                this.animPos = Math.max( 0, Math.min( 1000, this.$el.offsetTop - window.scrollY + 400 ) );
                gsap.to( this.$refs.visual, 0.1, { backgroundPositionY: ( this.animPos / 12 ) });
            },
            anim()
            {
                let tl = gsap.timeline();
                tl.from( '.what-we-do-best .visual', { opacity: 0 });
                tl.from( '.what-we-do-best h2', { opacity: 0, y: 20 });
                tl.from( '.what-we-do-best li', { x: 20, autoAlpha: 0, delay: 0.3, stagger: true });

                tl.add( this.$refs.dot.anim(), 1 );

                return tl;
            }
        }
    };
</script>
