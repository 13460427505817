<template>
    <div :class="{ 'logo-wrapper': true, 'small': small }">
        <img class="part1" src="/static/img/logos/mad-avenue-logo-part1.png" />

        <div class="nue-container-big">
            <img class="part2" src="/static/img/logos/mad-avenue-logo-part2.png" />
        </div>

        <div class="nue-container-small">
            <img class="part2" src="/static/img/logos/mad-avenue-logo-part2.png" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    export default {
        props: {
            small: {
                type: Boolean,
                default: false
            }
        },

        mounted()
        {
        }
    };
</script>
