<template>
    <div class="mad-works-anim">
        <svg ref="svg" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 4356.6 1060.86"
             style="overflow:visible;enable-background:new 0 0 1346.55 331.19;" xml:space="preserve" class="anim-svg">
            <path stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path1" d="M1203.11,549.58c-29.25,43.87-53.41,92.05-72.54,143.13c-69.77,186.38-29.6,333.97,89.73,329.65
                c119.33-4.32,275.56-157.84,342.4-345.29c28.03-78.62,36.11-134.91,39.94-168.13c3.15-27.38-9.21-33.57-6.12-18.11
                c0,0,40.63,264.83,195.62,457.62c144.88,180.22,324.66-246.48,332.17-269.45c7.51-22.97,34.45-71.12,34.01-77.3
                c-0.44-6.18,26.54-90.73,26.94-94.97c0.41-4.24,12.37-13.25,7.51-38.87c-4.86-25.62-11.48-45.06-11.48-45.06
                s-10.01-27.98,35.93-23.12c0.15,9.87,6.04,27.53,26.06,27.98c20.02,0.44,22.23-0.15,21.64-0.59s-17.96-0.15-30.63,5.6
                c-12.66,5.74-9.46,15.79,9.13,22.82c5.45,2.06,18.41-1.77,15.9-2.06c-2.5-0.29-18.85,3.09-22.67,5.3c-3.83,2.21-4.12,4.27,2.21,6.48
                c6.33,2.21,14.43,3.53,12.96,2.5c-1.47-1.03-14.13,6.48-14.13,6.48s-0.88,8.69,12.37,6.77c1.18-0.15-4.71-1.33-9.42,2.5
                c-4.71,3.83-5.01,9.57-0.29,10.31c4.71,0.74,9.86,2.21,7.95,1.33s-8.69,0-11.19,3.68s-1.62,5.6,1.77,7.51
                c3.39,1.91,7.53,1.03,9.13,0.88c1.6-0.15-2.21,5.6-4.12,6.18c-1.91,0.59-4.56,0.44-3.09,0.29c1.47-0.15,8.83,0.59,9.57,5.6
                c0.74,5.01,4.71,44.32-1.47,87.02c-6.18,42.7-16.2,69.5-17.67,72.74c-1.47,3.24,0,5.6,4.12,0.29c4.12-5.3,18.26-44.76,18.55-78.33
                c-1.77-20.91-3.24-70.38-0.88-100.71c2.36-30.33,14.87-65.74,44.91-68.39c30.04-2.65,26.72,37.99,26.72,37.99
                s-1.55,20.32-15.9,23.41c-14.36,3.09-28.71-15.02-30.04-30.7c-1.33-15.68,12.37-19.21,16.79-19.88c4.42-0.66,17.01-1.99,19.44,3.09
                c2.43,5.08,12.15,40.86,12.59,58.31c0.44,17.45,0.66,76.64,2.87,95.41c2.21,18.77,5.3,24.07,5.3,24.07s4.2,25.62,2.87,44.17
                s-15.02,100.71-16.34,118.82c-0.22,1.55,10.16-66.7,15.46-85.03c5.3-18.33,16.12-25.62,15.68-26.94
                c-0.44-1.33-11.93-58.09-7.07-111.09c-13.47-35.34-28.82-60.76-18.55-114.85c3.31-17.45,28.41-20.43,33.79-18.77
                c5.74,1.77,10.6,6.85,13.03,11.04c2.43,4.2,11.71,28.71,9.72,36.44c-1.99,7.73-21.2,13.03-34.23,0.22
                c-13.03-12.81-11.04-20.76-10.38-27.39s9.72-19.21,24.29-14.36s22.75,22.53,26.28,32.91c3.53,10.38,38.28,150.18,40.93,163.14
                s9.13,43.88,5.59,53.6c-3.53,9.72-10.31,37.99-14.72,58.31s-8.54,51.24-9.13,53.89c-0.59,2.65,8.25-17.67,12.66-35.34
                s14.72-63.31,16.2-71.26c1.47-7.95,3.53-39.17-11.78-97.18c-15.31-58.01-46.53-144.88-47.71-150.48c-1.18-5.6-0.88-11.48-0.88-11.48
                s-14.65-8.11-8.54-36.22c1.47-6.77,15.61-20.91,20.02-48.59s-1.18-50.06,15.31-79.51c16.49-29.45,53.01-94.82,28.86-166.67
                C2412.9,29.09,2361.07,2,2293.93,2c-67.14,0-123.68,50.65-141.35,114.26c-17.67,63.61,16.49,120.15,23.56,133.69
                c7.07,13.55,33.57,25.33,28.86,47.71s-7.66,61.25,13.55,92.47c25.33-10.6,45.94-17.08,66.55-7.07c20.61,10.01,52.71,8.25,57.42-2.94
                c0,0,2.65-0.59-5.89,0c-8.54,0.59-53.3,2.06-64.49-7.36c-11.19-9.42-4.42-28.86,22.09-32.39c26.5-3.53,40.05,3.24,42.7,7.66
                s4.71,14.72-11.78,21.5s-35.63,1.18-38.58-5.3s-39.17-144.88-46.82-161.08c-7.66-16.2-22.97-20.32-28.56-14.13
                c-5.6,6.18,16.79,29.74,33.86,11.48c17.08-18.26,21.5-35.63,14.14-43.58c-7.36-7.95-24.74,0-17.96,10.9
                c6.77,10.9,25.62,22.97,45.35,10.9s26-28.68,27.39-35.63c1.39-6.95-1.77-10.01-10.01-10.01c-8.25,0-11.19,4.12-11.19,11.78
                c0,7.66,15.9,35.63,30.04,37.99c14.14,2.36,33.57-1.47,40.34-17.08s-18.55-13.25-21.5-5.3s-4.42,29.15,11.19,40.93
                c15.61,11.78,34.16,13.84,40.64,8.25c6.48-5.6,3.24-20.32-8.83-19.73c-12.07,0.59-26.8,50.06-35.34,76.27
                c-8.54,26.21-21.79,68.61-22.67,91.58c-0.88,22.97,0,35.63,31.51,40.05c4.71-1.77,13.55-6.18,19.14-0.59
                c-0.59,7.07-4.42,21.2,7.95,33.28c2.94,2.65,6.77,9.72,8.83,14.72c2.06,5.01,19.44,59.48,32.69,82.45s25.33,33.86,34.75,52.42
                c9.42,18.55,35.04,40.34,33.28,76.86c-1.77,36.52-25.91,81.28-28.86,85.4c-2.94,4.12-11.78,17.67-11.78,17.67
                s-11.78,37.69-15.31,51.83s-5.23,17.21-10.6,20.02c-6.15,3.22-18.35,11.98-31.58,21.75c-25.45,18.79-57.14,40.53-73.99,28.31"/>
            <path stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path2" d="M2322.64,881.9c-7.15-5.19,18.51-25.47,69.06-25.33c91.49,0.26,248.41,27.38,325.74,48.05
                c197.43,52.78,537.56,12.38,708.59-313.04c27.91-69.71,53.42-132.66,69.12-168.34c21.44-48.72,23.91-46.89,30.82-43.28
                c6.91,3.6,18.27,8.97,26.43,1.61c8.16-7.37,13.12-27.47,17.46-42.85c4.34-15.39,8.05-26.06,0.78-33.57
                c-7.27-7.51-25.52-11.87-24.83-29.58c0.69-17.71,20.33-48.78,38.78-68.45c18.45-19.67,35.72-27.94,43.08-36.28
                c7.35-8.35,4.79-16.78,0.18-15.22c-4.61,1.56-11.28,13.11-11.82,30.36c-0.54,17.25,5.04,40.2,3.24,57.85
                c-1.8,17.66-10.99,30.01-20.93,37.2c-9.95,7.19-20.66,9.21-31.4,9.47c-10.75,0.26-21.53-1.23-28.4,5.95
                c-6.87,7.18-9.82,23.02-13.58,36.34c-3.76,13.32-8.33,24.12-5.48,34.49c2.85,10.37,13.12,20.3,14.97,35.87
                c1.85,15.58-4.7,36.79-27.09,92.48c-22.39,55.69-60.61,145.85-91.5,217.1c-30.88,71.25-54.43,123.58-71.24,158.86
                s-26.9,53.49-38.24,64.63c-11.35,11.14-16.27,16.15-15.22,10.74c4.13-21.43,150.36-350.7,178.12-428.23
                c34.7-96.9,5.61-31.17-77.55-66.29c-20.18-8.52-31.1-22.48-48.39-35.02c-17.3-12.54-40.97-23.66-71.33-31.4
                c-30.36-7.74-67.41-12.1-101.26-9.39c-33.85,2.71-64.5,12.5-93.8,22.56c-29.29,10.07-57.23,20.41-81.36,30.55
                c-24.13,10.14-44.46,20.09-50.43,30.78c-5.97,10.7,2.43,22.16,21.68,25.19c19.25,3.03,49.35-2.36,76.35-7.66
                c27-5.3,50.91-10.5,65.11-7.56c14.2,2.94,18.71,14.01,23.58,21.07c4.87,7.07,10.11,10.13,14.89,18.16c4.78,8.04,9.09,21.05,8.75,35
                c-0.34,13.94-5.33,28.81-16.88,35.78c-11.55,6.97-29.65,6.05-36.46-0.38c-6.81-6.42-2.34-18.34-1.6-29.58
                c0.74-11.25-2.26-21.82-8.74-21.94c-6.48-0.12-16.46,10.21-26.98,14.39c-10.52,4.19-21.59,2.22-25.82-2.9
                c-4.23-5.12-1.62-13.41,4.74-23.09c6.36-9.68,16.46-20.75,27.3-33.36c10.83-12.61,22.4-26.77,36.59-31.26
                c14.19-4.49,31,0.7,36.82,9.3c5.82,8.6,0.64,20.62,3.19,28.99c2.55,8.37,12.83,13.08,24.01,6.88c11.18-6.2,23.27-23.31,35.94-37.27
                c12.67-13.96,25.93-24.78,39.48-30.37c13.55-5.59,27.39-5.95,35.78-2.64c8.39,3.31,11.34,10.3,12.4,19.97
                c1.06,9.66,0.25,22-3.08,35.18c-3.33,13.18-9.16,27.22-19.08,28.2c-9.92,0.98-23.91-11.09-33.18-15.47
                c-9.27-4.38-13.81-1.08-21.63,3.55c-7.82,4.63-18.92,10.57-27.19,8.92c-8.27-1.66-13.7-10.91-12.29-17.59
                c1.41-6.67,9.67-10.77,13.38-16.46c3.71-5.7,2.88-13,3.59-22.8c0.71-9.8,2.97-22.11,9.21-26.87c6.25-4.75,16.48-1.95,26.94-4.09
                c10.46-2.14,21.16-9.22,33.83-11.81c12.67-2.59,27.33-0.67,35.74,3.6c8.41,4.27,10.59,10.89,9.76,21.55
                c-0.82,10.66-4.65,25.35-4.31,37.4c0.34,12.05,4.84,21.44,14.28,27c9.44,5.55,23.82,7.27,32.76,15.89
                c8.94,8.62,12.42,24.16,8.02,41.45c-4.4,17.29-16.68,36.35-31.41,46.9c-14.73,10.55-31.91,12.61-41.44,7.3
                c-9.52-5.3-11.39-17.96-11.53-27.04c-0.14-9.08,1.44-14.58,1.31-22.83c-0.14-8.24-2-19.23,0.05-26.47c2.05-7.25,8-10.76,13.51-10.73
                c5.51,0.03,10.59,3.59,18.56-1.66c7.97-5.25,18.83-19.3,29.52-23.99c10.69-4.69,21.2-0.01,27.84,9.31
                c6.64,9.32,9.42,23.28,12.48,38.4c3.07,15.12,6.42,31.39-6.68,46.49c-13.1,15.1-42.67,29.04-64.15,35.2
                c-21.49,6.16-34.9,4.54-45.42,8.73c-10.52,4.18-18.15,14.17-23.88,24.84c-5.73,10.67-9.57,22.01-17.21,26.25
                c-7.64,4.24-19.08,1.37-34.57-1.37c-15.49-2.75-35.04-5.37-43.56-13.58c-8.51-8.21-6-21.99,0.65-28.12
                c6.64-6.13,17.41-4.6,25.72-7.5c8.31-2.9,14.18-10.23,22.62-15.55c8.44-5.32,19.45-8.61,30.53-6.54
                c11.08,2.08,22.24,9.54,27.07,21.16c4.83,11.62,3.32,27.41-4.07,42.28c-7.39,14.86-20.67,28.79-34.52,30.71
                c-13.85,1.92-28.27-8.17-42.8-13.33c-14.53-5.16-29.18-5.4-39.68-0.26c-10.5,5.14-16.85,15.66-21.42,28.55
                c-4.57,12.89-7.35,28.15-12.37,37.99c-5.02,9.84-12.27,14.26-22.5,15.53c-10.23,1.27-23.44-0.62-36.07,0.65
                c-12.63,1.26-24.67,5.67-33.76,11.44c-9.09,5.77-15.22,12.9-17.31,21.47c-2.09,8.57-0.14,18.58,6.59,21.18
                c6.73,2.6,18.24-2.21,30.15-6.72c11.91-4.51,24.22-8.72,33.07-8.87c8.84-0.15,14.21,3.77,21.4,3.79s16.19-3.86,23.57-12.33
                c7.37-8.47,13.11-21.53,14.97-32.46c1.86-10.93-0.15-19.74-9.46-25.19c-9.31-5.45-25.9-7.56-36.77-4.88
                c-10.86,2.68-15.99,10.14-24.9,12.29c-8.9,2.15-21.59-1.01-33.69,1.37c-12.1,2.38-23.63,10.31-30.23,19.18
                c-6.6,8.88-8.26,18.7-13.33,20.89s-13.54-3.27-18.65-11.98c-5.12-8.71-6.88-20.66-13.66-28.52c-6.79-7.86-18.6-11.63-23.66-8.61
                c-5.06,3.02-3.38,12.83-12.88,11.72c-9.51-1.11-30.2-13.15-50.38-13.53c-20.18-0.38-39.83,10.9-48.79,23.24
                c-8.96,12.34-7.23,25.73,2.08,32.03c9.31,6.29,26.2,5.48,39.54,11.54c13.35,6.06,23.16,18.98,31.34,20.05
                c8.18,1.07,14.74-9.72,23.27-16.84c8.53-7.13,19.04-10.59,27.4-13.14c8.37-2.55,14.59-4.19,15.4-10.89
                c0.81-6.71-3.8-18.48-10.91-30.13c-7.11-11.65-16.71-23.17-21.71-31.89c-5-8.72-5.39-14.65,4.6-21.63
                c9.99-6.98,30.36-15.01,43.08-25.39c12.72-10.38,17.79-23.1,11.97-32.88c-5.82-9.78-22.54-16.61-40.59-11.56
                c-18.06,5.06-37.46,22-42.87,37.63c-5.41,15.63,3.16,29.93,17.21,35.88c14.05,5.95,33.58,3.55,49.7-6.49
                c16.12-10.04,28.85-27.72,33.13-40.93c4.28-13.21,0.12-21.93-5.3-27.84c-5.42-5.91-12.1-8.99-20.21-19.3
                c-36.64-46.57,2.67-69.13-5.07-93.74c-4.5-14.31-26.81-25.87-33.38-37.95c-6.57-12.09,2.59-24.69,24.68-42.2
                c22.09-17.51,57.11-39.91,96.44-55.05c39.33-15.14,82.95-23,126.18-24.7c43.22-1.7,86.03,2.77,122.67,12.46
                c36.64,9.69,67.1,24.6,86.87,52.35c19.78,27.74,28.87,68.32,27.96,105.22c-0.91,36.9-11.82,70.12-44.29,113.11
                s-86.49,95.74-165.58,140.09c-79.09,44.35-183.25,80.3-263.28,79.28c-80.03-1.02-135.92-39.01-162.07-81.5
                c-24.52-39.86-22.71-98.51,0.02-140.91c46-85.79,143.25-40.85,190.33-80.29c10.61-8.89,0.54-64.58-39.93-77.85"/>
            <path stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path3" d="M3291.14,946.94c-38.13,16.25-110.43-51.44,53.47-75.37s442.07,23.93,571.08,63.02
                c129.01,39.09,44.96,33.23,44.96,33.23c-92.98,9.25-140.7-49.95-140.7-49.95c-13.34-19.2-33.63-63.73-23.02-122.04
                c4.19-23.03,20.62-53.22,40.25-86.22c4.22-9.81,41.31-22.1,79.9-22.1s66.71,8.59,66.71,19.2s-28.12,19.2-66.71,19.2
                s-72.19-9.98-72.2-29.18c26.92-45.26,50.65-80.39,50.99-99.2c1.3-71,1.31-112.93,0.26-123.08c-1.28-12.44-11.82-5.75-10.66-17.27
                c1.38-13.72,38.74-18.4,59.11-8.02c20.37,10.38,1.85,16.91,0.28,21.06c-1.57,4.15-2.84,73.15,0.23,124.91
                c2.35,39.66,31.13,71.55,59.23,125.64c29.79,57.35,36.54,79.63,28.02,146.84c-12.91,101.78-98.5,88.77-127.95,87.43
                c-38.88-1.77-62.26-32.11-57.56-41.5c8.03-16.06,243.39-10.65,306.08,8.03c62.69,18.68-22.49,43.22-99.28,37.02
                c-76.79-6.19-84.7-34.27-82.71-75.06c1.62-33.22,59.81-272.36,81.57-379.16c0,6.36,29.27,9.62,68.98,9.62s71.9-5.16,71.9-11.52
                s-32.19-13.05-71.9-13.05s-67.6-1.31-67.6,5.05c4.86-23.84,6.52-31.18,6.72-38.07c0.68-24.19,3.2-76.78,2.32-138.22
                c-0.36-24.83-23.19-24.57-10.19-44.12c9.83-14.77,148.72-24.44,167.59,1.12c12.83,17.37-15.67,14.56-21.19,30.01
                c-3.86,10.81-1.18,70.81-1.69,146.6c-0.17,24.72,60.59,283,80.96,382.61c20.37,99.62,14.1,112.75-70.52,107.95
                c0,0-17.03-0.55-16.02-22.86c0.94-20.8,45.05-6.63,45.04-37.27c-0.02-68.95,0.36-304.1-1.08-484.76c0-3.86,12.68-9.55,29.17-9.55
                c16.49,0,29.86,3.13,29.86,6.98c0,3.86-13.37,7.26-29.86,6.98c-22.62-0.38-29.57-17.26-29.57-21.12
                c-0.71-91.76-1.42-182.77-0.9-191.79c1.57-26.98,14.1-12.45,42.31-20.75c28.21-8.3-26.64-8.3-45.45-8.3c-11.85,0-9.7-20.28,0-18.68
                c13.29,2.2,55.81,3.83,67.39,0c1.35-0.45,10.59,0.59,10.12,11c-0.2,4.38-10.71,10.41-10.41,18.68
                c5.63,154.69,4.93,683.94,4.93,710.31c0,31.13,27.09,16.28,42.85,27.44c22.82,16.15-4.13,37.01-45.62,32.1"/>
            <path stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path4" d="M369.22,189.29c0,0-123.09-3.53-246.18,93.06s-83.04,163.14,16.49,141.35
                c99.53-21.79,320.98-121.91,386.94-187.29s98.94-147.24-75.98-111.31S103.6,242.29,31.75,325.34s2.94,98.36,51.24,93.06
                s136.05-20.02,224.98-65.37s154.9-87.17,190.23-155.48s-57.13-67.73-114.26-60.07c-57.13,7.66-185.52,38.28-266.8,101.89
                S-6.09,377.75,8.93,407.79s59.63,37.99,219.09-30.92s247.36-141.35,254.43-194.36s-170.5-21.2-259.73,19.44
                S47.28,293.31,22.62,370.69c-12.81,40.2,35.78,79.07,35.78,79.07"/>
            <path stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path5" d="M608.63,300.6c0,0-4.42-64.2-14.43-101.3c-15.31,15.9-27.09,35.93-55.66,53.01
                c5.6,12.66,20.32,38.58-3.24,62.72c-11.78,0.88-34.45-2.65-40.05-23.56c12.66,3.83,46.82,13.25,57.72,18.55
                c2.06,1.77,7.36,4.42-9.72,1.77s-20.02-2.94-20.02-2.94l11.19-9.42l-20.61,3.24l12.96-6.18h-24.44l15.9-4.42
                c0,0-32.39-7.36-42.7-17.96c0,0,49.77-49.77,91.88-17.08c-7.94-22.42-17.96-53.3-102.77-32.69c39.17-18.55,66.97-28.69,91.29-5.3
                c15.31,14.72,20.32,38.87,20.32,38.87s20.32,32.39,19.14,67.14c17.37,28.86,23.26,37.69,25.91,41.82
                c2.65,4.12,19.44,17.37,19.73,31.8c0.29,14.43,1.18,22.97-12.96,34.16c13.55-6.77,27.98-13.25,23.85-30.33s-22.16-15.85-33.28-13.84
                c-11.12,2.01-11.78,5.3-16.2,7.07c-4.42,1.77-27.68,10.01-22.97-5.3c-9.72,12.37-17.37,23.26-12.07,30.92s17.08,9.72,17.08,9.72
                s15.31-23.85,28.56-20.61c13.25,3.24,17.08,12.37,17.08,12.37s-17.96,7.95-18.85,25.03c-0.88,17.08,4.42,21.5,4.42,21.5
                s-5.3,24.74-15.61,36.22c1.18-9.42-2.94-25.33-45.06-12.07c14.13-9.72,26.21-16.2,30.63-19.44s8.25-7.95,14.13-6.48
                c5.89,1.47,20.39,6.09,20.32,16.49c-0.07,10.4-7.36,23.56-13.55,31.21c-6.18,7.66-10.9,12.37-10.9,12.37s5.89,8.54,6.48,12.37
                c0.59,3.83,2.94,6.48,5.6-1.47c2.65-7.95-1.77-21.2-21.2-28.56c-19.73-2.94-43.88-5.89-43.88-5.89s9.42,9.72,17.67,11.78
                c8.25,2.06,17.37,3.24,26.21,12.37c8.83,9.13,13.55,17.67,8.25,26.5s-6.91,11.19-7.95,12.66l1.03,1.33l-8.39-8.83
                c0,0,8.54,20.76,8.83,23.85c0.29,3.09-2.94,31.07-8.1,39.02c-5.15,7.95-5.89,18.11-25.91,10.9
                c-20.02-7.21-125.01-53.89-165.35-77.74s-64.79-43.58-65.37-51.53c-12.07-4.42-66.55-31.21-120.44,18.55
                c-4.12-7.66-13.25-23.85-13.25-23.85s38.58-17.08,72.44-16.79c33.86,0.29,37.69,25.62,37.69,25.62s-20.69,10.7-1.85,26.6
                c-17.88-15.41-28.19-19.54-47.92-16.89c-19.73,2.65-27.98,11.19-27.98,11.19s-10.01,11.78-10.31,19.14
                c-0.29,7.36,7.66,7.07,7.66,7.07s17.37-7.66,29.74-8.83c12.37-1.18,29.74-0.59,33.57-5.3l0.59-2.36c0,0,1.47,4.12-1.77,10.6
                s-15.31,20.61-58.01,19.44c1.18-4.12,2.36-12.37,2.36-12.37s13.56,10.65,19.44,8.54c7.36-2.65,10.31-6.77,10.01-11.19
                c6.18-1.47,42.99-8.83,50.94-10.9s14.28-3.02,15.61-3.9c1.33-0.88,2.43-2.43,6.85,4.2s24.52,34.9,20.76,52.56
                c-3.75,17.67-15.68,13.91-24.96,16.12c-9.28,2.21-37.33,10.38-52.56,41.08c-15.24,30.7-4.86,31.8-29.37,75.75
                c-24.52,43.95-24.96,35.78-33.57,56.1s-14.8,44.83-11.26,59.41c5.96-1.55,32.02-8.61,46.6-12.81c14.58-4.2,80.39-23.63,104.25-32.25
                c23.85-8.61,51.24-20.1,54.11-24.29c2.87-4.2,37.1-104.91,37.1-104.91s52.34,1.55,75.53-20.76c23.19-22.31,34.23-57.86,22.75-95.85
                s-53.89-13.25-60.52-9.72c-6.63,3.53-41.08,20.98-53.01,13.69s-16.12-17.01-28.71-51.24s-23.63-37.1-36.44-46.6
                c-12.81-9.5-26.94-18.99-26.94-18.99s10.38-18.33-8.17-34.45c-18.55-16.12-40.26-33.97-79.29-43.73
                c-8.83-2.21-21.42-1.99-33.13,12.59c-11.71,14.58-25.4,22.97-43.51,48.59c-18.11,25.62-23.41,36.44-26.28,62.72
                c-2.87,26.28,1.55,29.82-6.18,47.93c-1.55,3.09-4.15,4.83-8.39,3.31c3.98-0.44,20.32-3.31,20.32-3.31l11.93,23.19
                c0,0-11.93-5.52-20.98-3.98c-9.06,1.55-6.63,10.38-3.31,23.19c3.31,12.81,5.3,16.79,5.3,16.79l-9.5,2.43l6.18,2.43
                c0,0-13.69,9.5-17.45,12.59c-3.75,3.09-7.07,5.08-5.74,13.91s2.87,20.1,15.46,36.88c12.37-2.87,27.61-6.4,30.92-6.85
                c3.31-0.44,7.07,0.44,9.06,0c1.99-0.44,15.68-4.42,18.11-13.69s-3.31-14.36-9.94-19.66c3.31,4.86,8.61,13.69,1.77,18.33
                c-6.85,4.64-15.68,10.38-20.98,5.96c-5.3-4.42-9.72-7.51-11.71-5.52c-1.99,1.99-2.43,8.83-2.43,8.83s-10.16,8.17-7.95,14.36
                c2.21,6.18,5.3,14.13,5.08,20.54s-0.66,11.26-3.75,15.46c-3.09,4.2-2.65,13.25,0.88,24.74c0.98,3.19,3.53,7.51,3.53,7.51
                s12.15,3.75,17.45-16.56c15.24-0.22,21.64-0.66,30.26,0c8.61,0.66,23.85,3.31,22.97,1.99c-0.88-1.33-13.47-9.72-26.94-15.9
                c-13.47-6.18-20.98-11.04-28.27-8.17s-10.38,5.08-10.38,5.08s-10.38-3.75-13.91,4.64c-3.53,8.39,1.1,20.54,2.65,25.62
                c1.55,5.08,4.86,11.71,4.86,11.71s30.26-10.6,39.09-12.59s23.19-5.08,29.37-0.44c-6.4,1.1-24.96,4.86-41.52,9.28
                s-20.98,7.73-21.42,9.94c-0.44,2.21-1.12,8-0.44,10.82c0.68,2.82,4.64,1.33,10.38-1.55c5.74-2.87,20.76-12.15,20.76-12.15
                l-26.5,19.21l5.96,20.32c0,0-5.74,8.17-4.2,27.17c1.55,18.99,10.82,38.65,47.04,36.88s57.42-15.9,80.83,18.55
                c23.41,34.45,19.88,101.37,19.44,107.12s-2.73,18.28-2.73,18.28s212.17-175.97,378.19-201.15
                c165.79-25.14,421.59,158.54,448.78,198.77c24.01,35.53,79.51,55.66,109.55,64.49c71.36,20.99,172.27-8.83,258.85-133.4
                c86.27-124.13,113.77-178.95,134.07-355.92c0.15-1.28,0.12-2.58-0.1-3.86c-3.34-19.6-17.5-62.78-30.02-112.27
                c-12.96-51.24-60.36-102.12-123.63-99.83c-83.82,3.03-183.75,78.65-260.37,188.91"/>
            <path stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path6" d="M1287.33,623c14.11-17.36,31.58-28.8,46.75-28.81c24.23-0.02,33.41,29.08,20.51,65s-42.99,65.05-67.22,65.08
                c-24.23,0.02-33.41-29.08-20.51-65c2.8-7.81,6.42-15.29,10.61-22.23"/>
            <path stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path7" d="M1265.41,595.64c29.39-38.92,66.5-65.36,98.02-66.09c47.86-1.11,64.71,57.45,37.65,130.81
                s-87.8,133.72-135.65,134.83c-47.86,1.11-64.71-57.45-37.65-130.81c6.93-18.78,16.06-36.71,26.63-53.01"/>
            <path stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path8" d="M1247.57,577.3c44.24-60.73,100.34-102.76,147.25-104.71c69.62-2.9,92.6,83.59,51.34,193.17
                c-41.26,109.58-131.15,200.76-200.77,203.65c-69.62,2.9-92.6-83.59-51.34-193.17c11.38-30.23,26.47-59.06,43.8-85.02"/>
            <path stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path9" d="M1229.83,557.09c60.55-85.71,138.3-144.35,203.84-146.82c94.63-3.57,126.31,113.62,70.76,261.75
                s-177.29,271.11-271.92,274.69s-126.31-113.62-70.76-261.75c15.56-41.49,35.13-79.36,59.03-114.83"/>
            <path stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path10" d="M1307.58,647.2L1026.2,321.88c0,0,8.83-20.25,9.42-23.48c0.59-3.24-4.12-9.13-6.63-11.63
                c-1.21-1.21-3.68-3.53-3.68-3.53s-9.13,24.59-11.78,30.48c-2.65,5.89-2.36,5.15-7.95,7.21c-5.6,2.06-25.03,8.39-28.56,8.39
                c-3.53,0-11.66-10.89-13.18-12.63c-1.03-1.18-1.77-2.1-1.77-2.1s4.05,0.44,9.06-0.74c5.01-1.18,24.44-6.33,26.5-7.21
                s2.8-2.21,4.12-3.83c1.18-1.44,11.48-28.12,12.07-30.18c0.59-2.06-11.78-13.55-12.96-15.02s-0.88,2.65-2.36,7.07
                c-1.47,4.42-9.42,26.8-10.9,28.56c-1.47,1.77-8.54,2.06-13.55,2.94c-5.01,0.88-17.96,4.42-21.5,3.24s-14.72-12.66-16.2-14.43
                s-1.47-1.77-1.47-1.77s4.42,1.18,9.13,0c4.71-1.18,26.5-5.3,29.45-7.07c2.94-1.77,9.86-24.44,9.86-27.39s-13.4-13.55-14.58-14.72
                c-1.18-1.18,0.29,2.06-0.29,5.01c-0.59,2.94-4.12,23.56-5.01,25.62s-12.07,5.01-12.07,5.01l-11.19-12.96c0,0,0-5.89,1.18-8.25
                s4.12-4.42,9.13,2.06s344.58,406.68,348.66,411.68C1306.03,670.61,1321.27,660.67,1307.58,647.2"/>
            <circle stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path11 bubble" cx="4154.66" cy="812.18" r="16.56"/>
            <circle stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path12 bubble" cx="4099" cy="727.37" r="8.61"/>
            <circle stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path13 bubble" cx="4167.03" cy="682.32" r="11.26"/>
            <circle stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path14 bubble" cx="4068.53" cy="653.16" r="8.61"/>
            <circle stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path15 bubble" cx="4139.42" cy="591.54" r="9.28"/>
            <ellipse stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path16 bubble" cx="4127.87" cy="619.52" rx="6.48" ry="8.31"/>
            <circle stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path17 bubble" cx="4081.78" cy="828.08" r="13.91"/>
            <circle stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path18 bubble" cx="3857.83" cy="822.78" r="9.94"/>
            <circle stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path19 bubble" cx="3939.99" cy="781.7" r="13.91"/>
            <circle stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path20 bubble" cx="3915.47" cy="838.02" r="6.63"/>
            <circle stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path21 bubble" cx="4258.02" cy="594.86" r="5.96"/>
            <circle stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path22 bubble" cx="4281.21" cy="531.91" r="13.25"/>
            <circle stroke-miterlimit="10" fill="none" stroke-width="2" stroke="#FFFFFF" class="path23 bubble" cx="4281.88" cy="647.86" r="5.96"/>
        </svg>
    </div>
</template>

<script>
    import { TweenMax, TimelineMax, Sine } from 'gsap';
    export default {
        mounted()
        {
            // path4 crown
            // path5 faces

            // path6 bull
            // path7 bull2
            // path8 bull3
            // path9 bull4
            // path10 arrow

            // path1 lamp

            // path2 paint

            // path3 lab
            // path11 bubble
            // path12 bubble
            this.createTimeline();
            /* let test = '.path12';
            var path1 = this.$el.querySelector( test );
            console.log( path1.getTotalLength() );
            TweenMax.set( test, {
                strokeDasharray: path1.getTotalLength(),
                strokeDashoffset: path1.getTotalLength()
            });
            var tl = new TimelineMax({ repeat: -1 });
            tl.to( path1, 10, { strokeDashoffset: 0, ease: Sine.easeInOut }, 0 ); */
        },

        methods: {
            restart()
            {
                this.timeline.restart();
            },
            createTimeline()
            {
                var tl = new TimelineMax({ repeat: -1, paused: true });
                tl.addLabel( 'start', 0 );

                let offset = 0;
                let pathInfos = this.getPathInfosFor( [ '.path4', '.path5' ] );
                tl.to( this.$refs.svg, 0.01, { x: '8%', ease: Sine.easeInOut }, 'start' );
                tl.to( '.steps .empatize', 1, { autoAlpha: 1 }, 'start+=0.5' );
                offset = 4 / pathInfos.total * pathInfos.paths[0].pathLength;
                tl.to( pathInfos.paths[0].$el, offset, { strokeDashoffset: 0, ease: Sine.easeInOut }, 'start' );
                offset = 4 / pathInfos.total * pathInfos.paths[1].pathLength;
                tl.to( pathInfos.paths[1].$el, offset, { strokeDashoffset: 0, ease: Sine.easeInOut });
                tl.to( pathInfos.paths[0].$el, 0.25, { stroke: '#ffffff', ease: Sine.easeInOut });
                tl.to( pathInfos.paths[0].$el, 0.25, { stroke: '#ffffff', ease: Sine.easeInOut });
                tl.addLabel( 'empatize' );

                pathInfos = this.getPathInfosFor( [ '.path6', '.path7', '.path8', '.path9', '.path10' ] );
                tl.to( this.$refs.svg, 1, { x: '-13%', ease: Sine.easeInOut }, 'empatize-=1.5' );
                tl.to( '.steps>*', 0.5, { autoAlpha: 0 }, 'empatize' );
                tl.to( '.steps .define', 1, { autoAlpha: 1 }, 'empatize+=0.5' );
                offset = 4 / pathInfos.total * pathInfos.paths[0].pathLength;
                tl.to( pathInfos.paths[0].$el, offset, { strokeDashoffset: 0, ease: Sine.easeInOut }, 'empatize' );
                offset = 4 / pathInfos.total * pathInfos.paths[1].pathLength;
                tl.to( pathInfos.paths[1].$el, offset, { strokeDashoffset: 0, ease: Sine.easeInOut });
                offset = 4 / pathInfos.total * pathInfos.paths[2].pathLength;
                tl.to( pathInfos.paths[2].$el, offset, { strokeDashoffset: 0, ease: Sine.easeInOut });
                offset = 4 / pathInfos.total * pathInfos.paths[3].pathLength;
                tl.to( pathInfos.paths[3].$el, offset, { strokeDashoffset: 0, ease: Sine.easeInOut });
                offset = 4 / pathInfos.total * pathInfos.paths[4].pathLength;
                tl.to( pathInfos.paths[4].$el, offset, { strokeDashoffset: 0, ease: Sine.easeInOut });
                tl.to( pathInfos.paths[4].$el, 0.25, { stroke: '#ffffff', ease: Sine.easeInOut });
                tl.to( pathInfos.paths[4].$el, 0.25, { stroke: '#ffffff', ease: Sine.easeInOut });
                tl.addLabel( 'define' );

                pathInfos = this.getPathInfosFor( [ '.path1' ] );
                tl.to( this.$refs.svg, 1, { x: '-35%', ease: Sine.easeInOut }, 'define' );
                tl.to( '.steps>*', 0.5, { autoAlpha: 0 }, 'define' );
                tl.to( '.steps .ideate', 1, { autoAlpha: 1 }, 'define+=0.5' );
                offset = 4 / pathInfos.total * pathInfos.paths[0].pathLength;
                tl.to( pathInfos.paths[0].$el, offset, { strokeDashoffset: 0, ease: Sine.easeInOut }, 'define' );
                tl.to( pathInfos.paths[0].$el, 0.25, { stroke: '#ffffff', ease: Sine.easeInOut });
                tl.to( pathInfos.paths[0].$el, 0.25, { stroke: '#ffffff', ease: Sine.easeInOut });
                tl.addLabel( 'ideate' );

                pathInfos = this.getPathInfosFor( [ '.path2' ] );
                tl.to( this.$refs.svg, 1, { x: '-55%', ease: Sine.easeInOut }, 'ideate' );
                tl.to( '.steps>*', 0.5, { autoAlpha: 0 }, 'ideate' );
                tl.to( '.steps .produce', 1, { autoAlpha: 1 }, 'ideate+=0.5' );
                offset = 4 / pathInfos.total * pathInfos.paths[0].pathLength;
                tl.to( pathInfos.paths[0].$el, offset, { strokeDashoffset: 0, ease: Sine.easeInOut }, 'ideate' );
                tl.to( pathInfos.paths[0].$el, 0.25, { stroke: '#ffffff', ease: Sine.easeInOut });
                tl.to( pathInfos.paths[0].$el, 0.25, { stroke: '#ffffff', ease: Sine.easeInOut });
                tl.addLabel( 'produce' );

                pathInfos = this.getPathInfosFor( [ '.path3' ] );
                tl.to( this.$refs.svg, 1, { x: '-78%', ease: Sine.easeInOut }, 'produce' );
                tl.to( '.steps>*', 0.5, { autoAlpha: 0 }, 'produce' );
                tl.to( '.steps .learn', 1, { autoAlpha: 1 }, 'produce+=0.5' );
                offset = 4 / pathInfos.total * pathInfos.paths[0].pathLength;
                tl.to( pathInfos.paths[0].$el, offset, { strokeDashoffset: 0, ease: Sine.easeInOut }, 'produce' );
                tl.to( pathInfos.paths[0].$el, 0.25, { stroke: '#ffffff', ease: Sine.easeInOut });
                tl.staggerFrom( '.bubble', 2, { y: 50, opacity: 0, ease: Sine.easeOut }, 0.15 );
                tl.addLabel( 'learn' );
                tl.to( this.$refs.svg, 1, { autoAlpha: 0, ease: Sine.easeOut });
                tl.addLabel( 'end' );

                this.timeline = tl;
            },
            getPathInfosFor( selectors )
            {
                let infos = { paths: [] };
                let sum = 0;
                for( let i in selectors )
                {
                    let path = this.$el.querySelector( selectors[i] );
                    infos.paths.push({
                        pathLength: path.getTotalLength(),
                        $el: path
                    });
                    sum += path.getTotalLength();
                    TweenMax.set( path, {
                        strokeDasharray: path.getTotalLength(),
                        strokeDashoffset: path.getTotalLength()
                    });
                }
                infos.total = sum;
                return infos;
            }
        }
    };
</script>

<style lang="scss">
    .mad-works-anim {
        // overflow: hidden;
        padding-bottom: 75%;
        position: relative;
        svg {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
        }
    }
</style>
