<template>
    <section class="title" ref="bg">
        <video src="/static/video/hero.mp4" muted loop autoplay preload playsinline></video>
        <div><h1>Forget all you know</h1></div>
        <div><h1>about agencies</h1></div>
    </section>
</template>

<style lang="scss" scoped>
    .title {
        video {
            position: absolute;
            top: 0;
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            z-index: 0;
            pointer-events:none;
            transform: translateX(-50%);
        }
        h1 {
            position: relative;
        }

        &>div:first-of-type{
            padding-right:2rem;
        }

        &>div:last-of-type{
            padding-left:7rem;
        }
    }
</style>

<script>
    import { gsap } from '@/vendor';

    export default {
        mounted()
        {
            document.addEventListener( 'scroll', this.onScroll.bind( this ) );
        },
        methods: {
            onScroll( e )
            {
                this.$refs.bg.style.backgroundPosition = 'center ' + ( window.scrollY / 3 ) + 'px';
            },
            anim()
            {
                let tl = gsap.timeline();
                // tl.from( this.$el.querySelector( 'h1' ), { x: 100, opacity: 0, duration: 0.5 }, 1 );
                // tl.from( this.$el.querySelector( 'h1 span' ), { y: 100, opacity: 0, ease: 'back', duration: 0.5 }, 1.5 );

                // tl.to( this.$el.querySelector( 'h1' ), { y: -10, yoyo: true, repeat: 1, duration: 0.15 }, 1.75 );
                // tl.to( this.$el.querySelector( 'h1 span' ), { y: 0, yoyo: true, repeat: 1, duration: 0.15 }, 1.75 );

                tl.from( '.title h1', { y: 100, duration: 0.5, stagger: 0.2 }, 1 );

                return tl;
            }
        }
    };
</script>
